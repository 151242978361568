






































































































































import { defineComponent, reactive, watch } from "@vue/composition-api";
import AnggotaFM from "../models/anggotaForm";
import { isAdmin } from "@/apps/accounts/modules/store";
import KonsulenFM from "../models/konsulenForm";
import ResidenFM from "../models/residenForm";
import router from "@/router";
import { SaveContext } from "@/apps/core/models/formModel";
import useFormModel from "@/apps/core/modules/useFormModel";

export default defineComponent({
  name: "AnggotaForm",
  props: {
    parentForm: Object,
    headerText: { type: String, required: true },
  },
  components: {
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup(props) {
    const paramsId = router.currentRoute.params.id;
    if (props.headerText === "ANGGOTA") {
      const requiredFields = ["nama", "gender", "email"];
      const nonRequiredFields = ["inisial", "noHp", "alamat", "catatan"];
      const form = new AnggotaFM(requiredFields, nonRequiredFields);
      const composition = useFormModel(form, paramsId);
      const instance = composition.instance;

      return {
        // Data
        anggota: instance,

        // Composition
        ...composition,

        // Computed
        isAdmin,
        isAllValid: form.getIsValid(),

        // Method
        saveAnggota: () => form.save(SaveContext.Update),
      };
    }

    const parentForm =
      props.headerText === "RESIDEN"
        ? (props.parentForm as ResidenFM)
        : (props.parentForm as KonsulenFM);
    const form = parentForm.anggotaForm;
    const formRef = reactive(form);
    // const composition = useFormModel(form);
    // const anggota = composition.instance;
    // const id = router.currentRoute.params.id;
    const context = paramsId ? SaveContext.Update : SaveContext.Create;

    const saveAnggota = () => parentForm.save(context);

    const instance = formRef.instance;
    // terkait loadData manual, tidak menggunakan useModelForm
    // sehingga perlu menambahkan watch untuk create
    if (context === SaveContext.Create) {
      watch(instance, () => {
        if (!formRef.validity.edited) {
          formRef.setEdited(true);
        }
      });
    }

    return {
      // Data
      anggota: instance,
      errorMap: formRef.errorMap, // reactive
      formRef,

      // Computed
      isAdmin,
      isAllValid: parentForm.getIsValid(),

      // Method
      saveAnggota,
      cancel: () => router.go(-1),
      // unWatchInstance,
      validate: (value: string) => formRef.validate(value),
    };
  },
});
