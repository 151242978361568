import axios from "axios";
import { ToastProgrammatic as Toast } from "buefy";
import APP_CONFIG from "./config";
// import { webpSupport } from "utils";

const checkWebpFeature = (feature: string, callback: Function) => {
  const kTestImages = {
    lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
    lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
    alpha:
      "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
    animation:
      "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
  } as Record<string, string>;
  const img = new Image();
  img.onload = () => {
    const result = img.width > 0 && img.height > 0;
    callback(feature, result);
  };
  img.onerror = () => callback(feature, false);
  img.src = "data:image/webp;base64," + kTestImages[feature];
};

let webpSupport = false;
checkWebpFeature("lossy", function(feature: string, isSupported: boolean) {
  webpSupport = isSupported;
});

export default class UploadPhoto {
  s3: Record<string, any> | null;
  awsDirname: string;
  notify: Function | null;
  filename: string | null;

  constructor(dirname: string, notify: Function | null = null) {
    this.s3 = null;
    this.awsDirname = dirname;
    this.notify = notify;
    this.filename = null;
  }

  showErrorMessage() {
    Toast.open("Berkas gagal di upload.");
  }

  getS3Url() {
    return this.s3?.url + this.s3?.fields.key;
  }

  resize(foto: File, callback: Function) {
    if (!this.s3) return;
    const height = APP_CONFIG.resizeHeight;
    const width = APP_CONFIG.resizeWidth;
    const reader = new FileReader();
    // const showErrorMessage = this.showErrorMessage;
    const url = this.getS3Url();
    reader.readAsDataURL(foto);
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.src = (event.target?.result as string) ?? "";
      (img.onload = () => {
        const elem = document.createElement("canvas");
        // if (!this.canUseWebP(elem)) this.upload(foto);
        let imgWidth = img.width;
        let imgHeight = img.height;
        if (imgWidth > width) {
          const scaleWFactor = width / imgWidth;
          imgHeight = imgHeight * scaleWFactor;
          imgWidth = width;
        }
        if (imgHeight > height) {
          const scaleHFactor = height / imgHeight;
          imgWidth = imgWidth * scaleHFactor;
          imgHeight = height;
        }
        elem.height = imgHeight;
        elem.width = imgWidth;
        const ctx = elem.getContext("2d");
        if (!ctx) return;
        ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
        if (webpSupport) {
          ctx.canvas.toBlob(
            (blob) => {
              if (!blob) return;
              const newFileName =
                foto.name.substr(0, foto.name.lastIndexOf(".")) + ".webp";
              const resizedFoto = new File([blob], newFileName, {
                type: "image/webp",
                lastModified: Date.now(),
              });
              callback(resizedFoto, url);
            },
            "image/webp",
            APP_CONFIG.resizeQuality
          );
        }
      }),
        (reader.onerror = (error) => error);
    };
  }

  upload(
    file: File | null,
    success: Function,
    fail: Function | null = null,
    done: Function | null = null
  ) {
    if (file == null) {
      success();
      return;
    }

    if (this.s3 == null) {
      this.showErrorMessage();
      return;
    }

    this.filename = file.name;
    if (this.notify) this.notify(true);

    const formData = new FormData();
    for (const [key, val] of Object.entries(this.s3.fields)) {
      formData.append(key, val as File);
    }
    formData.append("file", file, "file.name");
    axios
      .post(this.s3.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // onUploadProgress: progressEvent => {
        //   console.log(progressEvent.loaded / progressEvent.total);
        // }
      })
      .then(() => {
        if (success) success();
      })
      .catch(() => {
        if (fail) fail();
        this.showErrorMessage();
      })
      .finally(() => {
        if (done) done();
        if (this.notify) this.notify(false);
      });
  }

  getCurrentDatetime() {
    const currentdt = new Date();
    const mm = currentdt.getMonth() + 1; // getMonth() is zero-based
    const dd = currentdt.getDate();
    const hh = currentdt.getHours();
    const mi = currentdt.getMinutes();
    const se = currentdt.getSeconds();

    return [
      currentdt.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
      (hh > 9 ? "" : "0") + hh,
      (mi > 9 ? "" : "0") + mi,
      (se > 9 ? "" : "0") + se,
    ].join("");
  }

  async requestAWS(file: File | null, saveBerkas: Function | null) {
    const awsUrl = `${APP_CONFIG.baseAPIURL}/aws/`;
    let response = null;
    if (file !== null) {
      const re = /(?:\.([^.]+))?$/ as RegExp;
      const ext = re.exec(file.name);
      if (ext === null) return;
      const isImage = [".png", ".jpg", ".jpeg"].includes(ext[0]);
      const currDtime = this.getCurrentDatetime();
      const fname = isImage
        ? null
        : currDtime + "_" + file.name.split(" ").join("_");
      response = await axios.get(awsUrl, {
        params: { ext: ext[0], "app_name": this.awsDirname, filename: fname },
      });

      const data = JSON.parse(JSON.stringify(response.data));
      this.s3 = data.s3;
      if (isImage) {
        this.resize(file, (file: File) => {
          this.upload(
            file,
            () => {
              if (saveBerkas !== null) saveBerkas(this.getS3Url(), true);
            },
            null
          );
        });
      } else {
        this.upload(
          file,
          () => {
            if (saveBerkas !== null) saveBerkas(this.getS3Url(), false);
          },
          null
        );
      }
    } else {
      response = await axios.get(awsUrl, {
        params: { "app_name": this.awsDirname },
      });
      // .then((response) => {
      //   const data = JSON.parse(JSON.stringify(response.data));
      //   this.s3 = data.s3;
      //   console.log('done...');
      // })
      // .catch(() => {
      // perlu berikan pesan error toast atau snackbar
      // console.log(error);
      // });
      const data = JSON.parse(JSON.stringify(response.data));
      this.s3 = data.s3;
    }
  }
}
