


















































































import { defineComponent, onActivated, reactive } from "@vue/composition-api";
import { Anggota } from "../models/anggota";
import { listVM } from "../modules/store";
import router from "@/router";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import useListFilter from "@/apps/core/modules/useListFilter";

export default defineComponent({
  name: "AnggotaList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const composition = useInfiniteScrollList(listVM, false);
    const filterButtonConfigs: Record<string, any> = reactive([
      {
        field: "pengguna",
        valueMap: { "": "Pengguna", Konsulen: "Konsulen", Residen: "Residen" },
      },
      {
        field: "gender",
        valueMap: {
          "": "Gender",
          "Laki-Laki": "Laki-laki",
          Perempuan: "Perempuan",
        },
      },
    ]);

    const getIcon = (anggota: Anggota) => {
      if (anggota.konsulen) return "user-md";
      if (anggota.residen) return "user-nurse";
      return "user-tie";
    };

    const menuConfigs: Array<Record<string, any>> = [
      {
        label: "Tambah Residen",
        onClick: () => {
          router.push({ name: 'residen-add' });
        },
      },
      {
        label: "Tambah Konsulen",
        onClick: () => {
          router.push({ name: 'konsulen-add' });
        },
      },
    ];

    onActivated(() => {
      if (listVM.getRefreshRequest() !== "") {
        // debugger;
        // ada permintaan refresh list (dengan initial filtering)
        listVM.applyFilter(listVM.refreshRequest.filterMap);
        if (listVM.refreshRequest.filterMap) {
          // reset filtering buttons
          for (let idx = 0; idx < filterButtonConfigs.length; idx++) {
            filterButtonConfigs[idx].value = "";
          }
          const pengguna = listVM.refreshRequest.filterMap.pengguna;
          const gender = listVM.refreshRequest.filterMap.gender;
          if (pengguna) filterButtonConfigs[0].value = pengguna;
          if (gender) filterButtonConfigs[1].value = gender;
        }
        listVM.resetRefreshRequest();
      } else if (listVM.list.length === 0) {
        // ketika listview pertama kali dipanggil, meload data list
        // asumsinya kalau list kosong, perlu fetch data dulu
        composition.onPageChange();
      }
    });

    return {
      // Data
      filterButtonConfigs,
      menuConfigs,

      // Method
      getIcon,

      // Composition
      ...composition,
      ...useListFilter(),
    };
  },
});
