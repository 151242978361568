









































































































import { computed, defineComponent, onMounted, reactive } from "@vue/composition-api";
import { Location } from "vue-router";
import accountStateRef, {
  isAdmin,
  isResiden,
} from "@/apps/accounts/modules/store";
import { homeNextUrl, setHomeNextUrl } from "@/apps/core/modules/store";
import HomeVM, { Home } from "../models/home";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";

export default defineComponent({
  name: "Home",
  components: {
    CardInfo: () => import("@/apps/core/components/CardInfo.vue"),
  },
  setup() {
    const paramsId = accountStateRef.me.id ?? "";
    const homeVM = new HomeVM();
    const viewModelRef = reactive(homeVM);

    onMounted(async () => {
      if (homeNextUrl.value) return;
      viewModelRef.isLoading = true;
      await viewModelRef.fetch(paramsId);
      viewModelRef.isLoading = false;
    });

    if (homeNextUrl.value) {
      const nextUrl: Location = { path: homeNextUrl.value };
      // perlu menggunakan then, agar tidak race condition
      // dengan onMounted fetch
      router.push(nextUrl).then(() => setHomeNextUrl(""));
    }

    const filterMapKIUjian: Record<string, string> = {};
    const filterMapAktPerawatan: Record<string, string> = {};
    if (isResiden.value) {
      filterMapKIUjian.hasil = "Lulus";
      filterMapAktPerawatan.verifikasi = "Ya";
    } else {
      filterMapKIUjian.verifikasi = "Belum";
      filterMapAktPerawatan.verifikasi = "Belum";
    }

    const data = viewModelRef.instance as Home;
    const showReminder = computed(() => {
      return (data.ujianList && data.ujianList.length > 0) || (data.kiList && data.kiList.length > 0);
    })

    return {
      // Data
      data,
      filterMapAktPerawatan,
      filterMapKIUjian,
      viewModelRef,
      // paramsKiUjian,
      isAdmin,
      isResiden,

      // Method
      toUserDateFormat,

      // Computed
      homeNextUrl,
      showReminder
    };
  },
});
