import { Anggota, AnggotaAPI } from "./anggota";
import FormModel from "@/apps/core/models/formModel";
import { setRefreshRequest } from "../modules/store";
import { isEmail } from "@/apps/core/modules/utils/text";

export default class AnggotaFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new AnggotaAPI(),
      new Anggota(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }

  validate(field?: string) {
    // TODO: Cek mekanisme untuk custom validation
    // Pastikan agar tidak bentrok dengan validate required.
    // Pastikan agar jika nonRequired, pesan error tetap muncul.
    if (field === "email") {
      const instance = this.instance as Anggota;
      const value = instance[field] ?? "";
      if (!isEmail(value)) {
        this.errorMap[field] = "Isi dengan alamat email yang benar.";
      }
    }
    super.validate(field);
  }

  getPayload(): Record<string, any> {
    const anggota = super.getPayload();
      if (anggota.email !== undefined)
        anggota.user = { username: anggota.email };
      delete anggota.email;
    return anggota;
  }
}
