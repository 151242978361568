import { onMounted, reactive } from "@vue/composition-api";
import ViewModel from "../models/viewModel";

const useViewModel = (
  id: string,
  viewModel: ViewModel,
  params = {} as Record<string, string>
) => {
  const viewModelRef = reactive(viewModel);
  // semua component yang berakhiran Form, Update, Detail,
  // atau Create di-exclude dari keep-alive
  // lihat core/layouts/MainAppLayout.vue
  onMounted(async () => {
    viewModelRef.isLoading = true;
    await viewModelRef.fetch(id, params);
    viewModelRef.isLoading = false;
  });

  return {
    viewModelRef,
    instance: viewModelRef.instance,
  };
};

export default useViewModel;
