















































































import axios from "axios";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { ToastProgrammatic as Toast } from "buefy";
import accountStateRef, { isAdmin, isKonsulen } from "@/apps/accounts/modules/store";
import AnggotaVM, { Anggota } from "../models/anggota";
import APP_CONFIG from "@/apps/core/modules/config";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import UploadPhoto from "@/apps/core/modules/uploadPhoto";
import useViewModel from "@/apps/core/modules/useViewModel";

export default defineComponent({
  name: "AnggotaDetail",
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    PencapaianKonsulen: () => import("@/apps/anggota/components/PencapaianKonsulen.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    let paramsId: string;
    const isMe = router.currentRoute.path.endsWith("me");
    if (isMe && accountStateRef.me.id) {
      // Gunakan pk dari user yang sedang login
      paramsId = accountStateRef.me.anggota?.id ?? "";
    } else {
      paramsId = router.currentRoute.params.id;
    }

    const anggotaVM = new AnggotaVM();
    const { viewModelRef, instance } = useViewModel(paramsId, anggotaVM);
    const anggota = instance as Anggota;

    let menuConfigs: Array<any> = [];
    if (isMe) {
      menuConfigs = menuConfigs.concat([
        {
          label: "Ubah Sandi",
          onClick: () => router.push({ name: "change-password" }),
        },
        {
          label: "Bantuan",
          onClick: () => router.push({ name: "bantuan" }),
        },
        {
          label: "Keluar",
          onClick: () => {
            accountStateRef.me.logout();
            return router.push({ name: "login" });
          },
        },
      ]);
    }

    // Perlu menggunakan watch, karena data anggota (residen, konsulen)
    // belum terload, menunggu fetch.
    watch(anggota, () => {
      let editLocation: Record<string, any> = { name: null };
      if (anggota.residen !== null) {
        editLocation = {
          name: "residen-change",
          params: { id: anggota.residen.id },
        };
      } else if (anggota.konsulen !== null) {
        editLocation = {
          name: "konsulen-change",
          params: { id: anggota.konsulen.id },
        };
      } else if (accountStateRef.me?.anggota?.id == paramsId) {
        editLocation = {
          name: "anggota-change",
          params: { id: paramsId },
        };
      }

      if (editLocation.name) {
        menuConfigs.unshift({
          label: "Ubah",
          onClick: () => router.push(editLocation),
        });
      }
    });

    const fotoDataRef = ref(null);
    const canUpload = computed(
      () => isAdmin || accountStateRef.me.anggota?.id === paramsId
    );
    const isUploading = ref(false);
    const uploadPhoto = new UploadPhoto("anggota", (uploading: boolean) => {
      isUploading.value = uploading;
    });

    const saveFoto = async (url: string) => {
      const uploadDocUrl = `${APP_CONFIG.baseAPIURL}/anggota/${paramsId}/`;
      const data = { foto: url };
      try {
        const response = await axios.patch(uploadDocUrl, data);
        anggota.foto = response.data.foto;
        fotoDataRef.value = null;
        Toast.open("Foto berhasil di upload.");
      } catch {
        Toast.open("Foto gagal di upload.");
      }
    };

    const uploadFile = (foto: File) => {
      uploadPhoto.requestAWS(foto, saveFoto);
    };

    return {
      // Data
      viewModelRef,
      anggota,
      menuConfigs,
      fotoDataRef,
      isUploading,

      // Computed
      canUpload,
      isKonsulen,
      // foto,

      // Method
      uploadFile,
      toUserDateFormat,
      uploadPhoto,
    };
  },
});
