import APP_CONFIG from "@/apps/core/modules/config";

const truncate = (text: string, limit = APP_CONFIG.textLimit) => {
  if (text.length > limit) {
    return text.substring(0, limit) + "…";
  }
  return text;
};

const isEmail = (email: string) => {
  const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (emailRegExp.test(email)) {
    return true;
  }
  return false;
};

// const dashIfEmpty = (text: string) => {
//   return text === "" ? "-" : text;
// }

export {
  // dashIfEmpty,
  isEmail,
  truncate,
};
