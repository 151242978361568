import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

export class Home extends PlainModel {
  ilmiahCount: number | null = null;
  ujianCount: number | null = null;
  perawatanCount: number | null = null;
  aktivitasCount: number | null = null;
  targetDiagCount: number | null = null;
  targetKetCount: number | null = null;
  ilmiahLastUpdate: string | null = null;
  ujianLastUpdate: string | null = null;
  perawatanLastUpdate: string | null = null;
  aktivitasLastUpdate: string | null = null;
  kiList: Array<string> | null = null;
  ujianList: Array<string> | null = null;
}

export class HomeAPI extends API {
  static modelPath = "/anggota/dashboard/";

  constructor() {
    super(HomeAPI.modelPath);
  }
}

export default class HomeVM extends ViewModel {
  constructor() {
    super(new HomeAPI(), new Home());
  }
}
