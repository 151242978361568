import { ref } from "@vue/composition-api";

const useListFilter = () => {
  const isOverlayActive = ref(false);
  const onTap = () => (isOverlayActive.value = false);
  const onSearch = (value: boolean) => (isOverlayActive.value = value);

  return {
    // Data
    isOverlayActive,

    // Method
    onSearch,
    onTap,
  };
}

export default useListFilter;