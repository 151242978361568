




import { defineComponent, onActivated, ref } from "@vue/composition-api";

export default defineComponent({
  name: "AnggotaMe",
  components: {
    AnggotaDetail: () => import("../views/AnggotaDetail.vue"),
  },
  setup() {
    const compIdStr = new Date().getTime();
    const compId = ref(compIdStr);
    onActivated(() => (compId.value = new Date().getTime()));

    return {
      compId,
    };
  },
});
